* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  /*width: 85vw;*/
  /*max-width: 1900px;*/
  margin: 0 auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  border-bottom: 1px solid black;
}
.navbar-menu-container {
  margin-right: 2rem;
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.nav-logo-container {
  margin-left:2rem;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  margin-left: 5rem;
  margin-right: 5rem;
  padding-top: 3rem;
  position: relative;
  display: flex;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}
.about-section-container {
  margin-top: 5rem;
  position: relative;
}

.about-background-black-image-container {
  position: relative;
  background-color: black;
  background-position: center;
  height: 7rem;
  z-index: 5;
}
.about-background-black-image-container h3 {
  color: white;
  font-size: 2vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  right: 0;
}

.about-background-image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.about-background-image {
  width: 100%;
  display: block;
}
.about-center-text {
  position: absolute;
  bottom: 50%;
  transform: translate(50%, 130%);
  text-align: center;
  max-height: 130px;
}
.about-center-text h2 {
  font-size: 5vw;
  color: white;
}

.about-card-container-group {
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.about-card-container {
  width: 33.3%;
}

.work-card-container-group {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 6rem;
  background-color: #ff5a0b;
  justify-content: center;
}

.work-card-container {
  min-width: 14%;
  max-width: 18%;
}

.testimonial-card-container-group {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  padding-bottom: 6rem;
  justify-content: center;
}

.about-section-image-container {
  flex: 0.9;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

/* -- CONTACT SECTION -- */
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  position: relative;
}
.contact-page-wrapper .fruits {
  position: absolute;
}
.contact-page-wrapper .f_left {
  left: 90px;
  top: -50px;
}
.contact-page-wrapper .f_right {
  right: 35px;
  top: -125.5px;
}
@media (max-width: 1280px) {
  .contact-page-wrapper .f_left {
    left: 30px;
  }
  .contact-page-wrapper .f_right {
    right: 13.75px;
  }
}
@media (max-width: 1150px) {
  .contact-page-wrapper .f_left {
    left: 10px;
  }
  .contact-page-wrapper .f_right {
    right: 5.75px;
    top: -145px;
  }
}
@media (max-width: 1000px) {
  .contact-page-wrapper .f_left {
    left: -15px;
  }
  .contact-page-wrapper .f_right {
    right: -45px;
  }
}
@media (max-width: 940px) {
  .contact-page-wrapper .f_left {
    display: none;
  }
  .contact-page-wrapper .f_right {
    display: none;
  }
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}

.contact-form-container {
  display: grid;
  place-items: center;
}
.contact-box-input {
  display: flex;
  gap: 38px;
  margin: 25px 0 32px 0;
}
.contact-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-inputs input {
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 2.4rem;
  border-radius: 5rem;
  flex: 1;
}
.contact-box-input textarea {
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 1.2rem 1.5rem;
  border-radius: 1rem;
  resize: none;
}

/* .contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
} */
/* .contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
} */

/* -------------------------------------- */

.contact-page-dir {
  display: flex;
  justify-content: space-evenly;
}

.contact-page-dir div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.contact-page-dir .first {
  transform: translateY(9.5px);
}

.tef {
  display: flex;
  gap: 40px;
}
.ppp {
  width: 110px;
  padding: 0 !important;
  padding: 0 20px !important;
}

/* -------------------------------------- */

.footer-wrapper {
  margin: 6rem 4rem;
  display: flex;
  margin-top: 10rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.about-transform-1{
  transform: translate(60%, 130%);
}
.about-transform-2{
  transform: translate(186%, 130%);
}
.about-center-text {
  position: absolute;
  bottom: 50%;
  text-align: center;
  max-height: 130px;
}
.about-center-text h2 {
  font-size: 5vw;
  color: white;
}
.testimonial-background-title {
  position: relative;
  background-color: #ff5a0b;
  background-position: center;
  height: 16rem;
  z-index: 5;
}

.testimonial-background-title h2 {
  font-size: 5vw;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  right: 0;
}

.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.work-background-image-container {
  position: relative;
  width: 100%;
  height: auto;
}
.work-background-image {
  width: 100%;
  display: block;
}
.work-center-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 130%);
  text-align: center;
  max-height: 130px;
}
.work-center-text h2 {
  font-size: 5vw;
  color: white;
}
.work-center-text p {
  font-size: 2vw;
  color: white;
}

@media (max-width: 1200px) {
  .work-card-container {
    min-width: 28%;
    max-width: 30%;
  }

  .work-card-container-group {
    padding-top: 5rem;
  }
}
@media (max-width: 1000px) {
  .about-card-container-group {
    display: flex;
    gap: 2rem;
    padding: 2rem;
  }
  .about-card-container {
    width: 33.3%;
  }
  .work-card-container-group {
    padding-top: 4.5rem;
  }

  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  .about-card-container {
    width: 100%;
  }

  .about-card-container-group {
    display: block;
    gap: 2rem;
    padding: 2rem;
  }

  /*----- contact -----*/
  .contact-box-input {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .work-center-text {
    position: absolute;
    top: 0;
    left: 35%;
    transform: translate(-25%, 75%);
    text-align: center;
    max-height: 130px;
  }
  .work-center-text h2 {
    font-size: 6.7vw;
    color: white;
  }
  .work-center-text p {
    font-size: 2.8vw;
    color: white;
  }

  .testimonial-background-title {
    position: relative;
    background-color: #ff5a0b;
    background-position: center;
    height: 8rem;
    z-index: 5;
  }

  .testimonial-background-title h2 {
    font-size: 6.7vw;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    right: 0;
  }

  .about-center-text {
    position: absolute;
    bottom: 50%;
    transform: translate(20%, 100%);
    text-align: center;
    max-height: 130px;
  }

  .about-center-text h2 {
    font-size: 7vw;
    color: white;
  }

  .about-background-black-image-container {
    position: relative;
    background-color: black;
    background-position: center;
    height: 4.4rem;
    z-index: 5;
  }
  .about-background-black-image-container h3 {
    color: white;
    font-size: 2.8vw;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    right: 0;
  }

  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .about-card-container-group {
    display: block;
    gap: 2rem;
    padding: 2rem;
  }

  .about-card-container {
    width: 100%;
  }

  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 100%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .work-section-container {
    margin-top: 5rem !important;
  }

  .work-card-container {
    min-width: 80%;
    max-width: 81%;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

  .footer-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    /*max-width: 95vw;*/
  }

  .footer-logo-container {
    max-width: 300px;
  }

  .footer-icons {
    margin-top: 8px;
  }

  /**/
  .contact-page-dir {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .contact-page-dir .first {
    transform: translateY(0px);
  }

  .contact-inputs {
    gap: 12px;
  }
  .contact-inputs input {
    margin-bottom: 0 !important;
    text-align: left;
    padding: 8px 26px !important;
  }
  .contact-box-input {
    gap: 16px;
  }
  .tef {
    gap: 16.5px;
  }
  .ppp {
    max-width: 100px !important;
  }

  .footer-wrapper {
    margin-top: 80px;
  }

  .about-transform-1{
    transform: translate(29%, 130%);
  }
  .about-transform-2{
    transform: translate(117%, 130%);
  }
}

@media (max-width: 600px) {
  .home-bannerImage-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .contact-page-wrapper h1 {
    white-space: nowrap;
    font-size: 1.5rem;
  }
  .contact-inputs {
    gap: 10px;
    /* align-items: center; */
  }
  .first {
    gap: 3px !important;
  }

  .contact-inputs {
    align-items: center !important;
  }
  .contact-inputs input {
    width: 90%;
    text-align: left;
  }
  .contact-inputs .tef {
    gap: 6px;
    width: 90%;
  }
  .contact-box-input {
    align-items: center;
  }
  .contact-box-input textarea {
    width: 90%;
  }
}

