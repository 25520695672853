/* Modal Styles */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
}
.harvest {
  width: 52% !important;
}
.product {
  width: 52% !important;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.image-descriptions {
  display: flex;
  flex-direction: column;
  width: 97% !important;
  max-height: 70vh;
  overflow-y: auto;
}

.image-description {
  display: flex;
  align-items: center;
  margin-bottom: 22.5px !important;
  flex-direction: column;
  /* justify-content: center; */
}

.boxImageDescription {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 630px) {
  /* Cambia a un diseño de fila en dispositivos más pequeños */
  .image-descriptions {
    text-align: center;
  }

  .boxImageDescription {
    flex-direction: column;
  }

  .boxImageDescription .boxTexto p {
    transform: translateY(-4.2px) !important;
  }

  .boxContImgTxt {
    /* max-width: 10rem; */
    overflow: hidden;
    flex: 0;
    display: flex;
    flex-direction: column;
  }

  .boxContImgTxt > * {
    flex: 0;
  }

  .boxContImgTxt p {
    overflow-wrap: break-word;
    white-space: normal;
    text-align: center;
  }

  .boxImageDescription .boxTexto {
    display: flex;
    flex-direction: column;
  
    color: #009c34;
    font-size: 18px;
    font-weight: bold;
  
    white-space: nowrap;
  }
  /* .modal-content {
    width: 90%;
  } */
  .harvest {
    width: 100% !important;
  }
  .product {
    width: 90% !important;
  }
}

.imageBox {
  max-width: 27rem; 
  max-height: 10rem; 
  width: 100%;
  overflow: hidden; 
}

.imageBox img {
  width: 100%;
  height: auto;
}

.boxImageDescription .boxTexto {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5px;

  color: #009c34;
  font-size: 18px;
  font-weight: bold;

  white-space: nowrap;

  justify-content: space-between;
}
.boxImageDescription .boxTexto p {
  transform: translateY(3.5px);
}

.boxImageDescription .boxTexto button {
  display: inline-block;
  padding: 6.5px 22px;
  /* background-color: #3498db; */
  background-color: #009c34;
  color: #ffffff;
  border: none;
  border-radius: 15px; /* Ajusta este valor según tus preferencias */
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.image-description h3 {
  color: #ff4040;
  font-size: 2em;
}

.boxContImgTxt {
  max-width: 10rem;
  min-width: 10rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.boxContImgTxt > * {
  flex: 1;
}

.boxContImgTxt p {
  overflow-wrap: break-word;
  white-space: normal;
  text-align: center;
}

/* ----------- imagen emergente ------------- */

/* Agrega un fondo oscuro al fondo del modal */
.modal.open::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.image-modal img {
  max-width: 90%;
  max-height: 90%;
}

.close-image-button {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 50px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1;
}

@media (min-width: 1580px) {
  .harvest {
    width: 70% !important;
  }
  .product {
    width: 42% !important;
  }
}