/* Modal Styles */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal.open {
    display: flex;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    /* width: 100%; */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 1rem;
  }
  
  .modal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .image-descriptions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* width: 70%; */
  }
  
  .image-description {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* .image-description img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  } */
  .modal-title{
    display: flex;
    justify-content: center;
    font-size:1.9rem;
  }
.modal-over-button{
  position: relative;
}

@media (max-width: 800px) {
  .modal-title{
    display: flex;
    justify-content: center;
    font-size:1.1rem;
  }
}
