/* Card.css */

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    /* display: grid;
    place-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--plomo, #4C4C4C);
    background: var(--blanco, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 15rem;
  }
  
  .card-image {
    max-height: 50px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .card-subtitle {
    font-size: 1.5rem;
    margin: 3px 0;
    color: #333;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
  }
  
  .testimonial-card-container-group .card {
    width: 287.27px;
  }

  @media (max-width: 305px) {
    .testimonial-card-container-group .card {
      width: 250px;
    }
  }