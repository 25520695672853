/* Card.css */

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    /* display: grid;
    place-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--plomo, #4C4C4C);
    background: var(--blanco, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .card-image {
    max-height: 6rem;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .card-subtitle {
    font-size: 1.2rem;
    margin: 3px 0;
    color: #333;
    min-width: 100px;
  }
  
  .card-button {
    font-size: 1.4rem;
    color: white;
    border-radius: 100px;
    background:  #FE9E0D;
    border: 0;
    min-width: 40%;
    padding: 0 1rem;
  }
  .card-button:hover {
    cursor: pointer;
  }
  